import React from 'react'

const Loading = () => {
    return (
        <div className='w-full min-h-screen flex flex-col justify-center items-center'>
            <p className='text-green-500 text-4xl lg:text-6xl font-ubuntu font-bold'>En cours de téléchargement</p>
        </div>

    )
}

export default Loading